import React from "react";
import Layout from "../components/Layout";
import { Link, graphql } from "gatsby";
import { GatsbyImage } from "gatsby-plugin-image";
import "./instructors.sass";
import { SEO } from "../components/SEO";


const Instructor = ({ data }) => {
  const { edges } = data.allMarkdownRemark;

  return (
    <Layout>
      <div className="section-container">
        <section className="section">
          <h1>Prowadzący</h1>
          <div className="section-grid">
            {
              edges.map((edge) => {
                const { frontmatter } = edge.node;
                return (
                  <Link to={`/instructor${edge.node.fields.slug}`} key={edge.node.id}>
                    <div className="instructor-wrapper">
                      <div className="instructors-background-container">
                        <GatsbyImage image={frontmatter.background.childImageSharp.gatsbyImageData} alt="Instructor background" />
                        <div className="instructor-background" >
                          <div className="circle-image">
                            <GatsbyImage image={frontmatter.image?.childImageSharp.gatsbyImageData} alt="Instructor image" />
                          </div>
                        </div>

                      </div>
                      <div>
                        <div className="instructor-info">
                          <h5 className="h5-black" style={{
                            textTransform: "uppercase",
                            fontSize: "1.25rem",
                            fontWeight: "600"
                          }}>{frontmatter.name} {frontmatter.surname}</h5>
                          <p>{frontmatter.profession}</p>
                        </div>
                      </div>
                    </div>
                  </Link>
                )
              })
            }
          </div>
        </section>
      </div>
    </Layout>
  )
}

export default Instructor;

export const Head = () => (
  <SEO
    title="Prowadzący"
    description="Prowadzący"
    path="/instructor"
  />
)


export const pageQuery = graphql`
query MyQuery {
  allMarkdownRemark(
    sort: {fields: frontmatter___surname, order: ASC}
    limit: 1000
    filter: {frontmatter: {templateKey: {eq: "instructor"}}}
  ) {
    edges {
      node {
        id
        fields {
          slug
        }
        frontmatter {
          templateKey
          background {
            childImageSharp {
              gatsbyImageData(height: 128)
            }
          }
          image {
            childImageSharp {
              gatsbyImageData(width: 104, height: 104)
            }
          }
          name
          surname
          profession
        }
      }
    }
  }
}
`

